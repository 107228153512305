<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-right"> 
        <v-btn
          color="primary"
          dark
          class="mb-2 mt-2 mr-3"
          @click="enabledDisabledAll()"
        >Habilitar / Deshabilitar Todos</v-btn>
        <v-btn
          color="primary"
          dark
          class="mb-2 mt-2"
          to="/overallPromotion"
        >Interacción General</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="10">
            <v-card-title>Tags
              <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
              dense
              :headers="headers"
              :items="tags"
              :search="search"
              :sort-by="['clientId', 'tagId']"
              multi-sort
            >

              <template v-slot:[`item.enabled`]="{ item }">
                <v-checkbox
                  v-model="item.enabled"
                  disabled
                ></v-checkbox>
              </template>

              <template v-slot:[`item.url`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-if="item.url != null" v-bind="attrs" v-on="on">{{item.url}}</span>
                    <!--<span v-if="item.url != null && item.url.length < 40" v-bind="attrs" v-on="on">{{item.url}}</span>
                    <span v-if="item.url != null && item.url.length > 40" v-bind="attrs" v-on="on">{{item.url.substring(0,39)+"..."}}</span>-->
                  </template>
                  <span>{{item.url}}</span>
                </v-tooltip>
              </template>

              <template v-slot:top>
                <v-dialog v-model="dialog" max-width="500px" persistent>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Modificar Tag</span>
                    </v-card-title>
                    <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="3">
                          <v-text-field
                            filled
                            readonly
                            v-model="editedItem.clientId"
                            label="Id Cliente"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-text-field
                            filled
                            readonly
                            v-model="editedItem.tagId"
                            label="Id Tag"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            filled
                            readonly
                            v-model="editedItem.clientDesc"
                            label="Cliente"
                          ></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="editedItem.desc"
                            label="Descripción Tag"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="editedItem.urlDesc"
                            label="Descripción URL"
                        ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            v-model="editedItem.url"
                            label="URL"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12">
                          <v-checkbox
                            v-model="editedItem.enabled"
                            label="Habilitado"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-container>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      @click="close"
                      >
                        Cancelar
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="save"
                    >
                      Guardar
                    </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>


                <v-dialog v-model="dialogFile" max-width="750px" persistent>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Subir Archivo</span>
                    </v-card-title>
                   
                    <v-card-text class="text-center">
                      <v-progress-circular
                        v-if="showLoading == true"
                        indeterminate
                        color="primary"
                        size="70"
                        width="7"  
                      ></v-progress-circular>
                      <v-img
                        v-if="urlFile != ''"
                        max-width="350"
                        :src="urlFile"
                        class="mx-auto"
                      ></v-img>

                      <v-text-field
                      v-if="localFile != null"
                        v-model="localFile.name"
                        label="Nombre de Archivo"
                        readonly
                        filled
                      ></v-text-field>
                    </v-card-text>


                    <v-card-actions class="justify-center">

                      <!--COMMENT: Input no visible por la clase d-none y ligado al botón cargar imagen-->

                      <input type="file" ref="inputFile" class="d-none" @change="selectFile($event)">

                      <v-btn color="primary" @click="closeFile">Cancelar</v-btn>
                      <v-btn color="primary" @click="$refs.inputFile.click()">Seleccionar Archivo</v-btn>
                      <v-btn color="primary" :disabled="!localFile" @click="saveFile()">Guardar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>


                <v-dialog v-model="dialogEnabledDisabled" max-width="450px" persistent>
                  <v-card>
                    <v-card-title class="text-center">
                      <span class="text-h5">Habilitar / Deshabilitar Todos</span>
                    </v-card-title>
                   
                    <v-card-text class="text-center">

                      <v-progress-circular
                        v-if="showLoading == true"
                        indeterminate
                        color="primary"
                        size="70"
                        width="7"  
                      ></v-progress-circular>

                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-select
                              v-model="clientEnabledDisabled"
                              :items="user.clients"
                              label="Selecciona Cliente"
                              dense
                              required
                              item-text="clientDesc"
                              item-value="clientId"
                            >
                            </v-select>
                            <v-checkbox
                              cols="12"
                              v-model="enabledAll"
                              label="Habilitado"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions class="justify-center">
                      <v-btn color="primary" @click="closeEnabledDisabled">Cancelar</v-btn>
                      <v-btn color="primary" :disabled="clientEnabledDisabled===''" @click="saveEnabledDisabledAll()">Guardar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

              </template>

              <template v-slot:[`item.actions`]="{ item }">

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar Tag</span>
                </v-tooltip>

                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editFile(item)"
                      color="green"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-file
                    </v-icon>
                  </template>
                  <span>Subir Archivo</span>
                </v-tooltip> -->

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="isAdmin"
                      small
                      class="mr-2"
                      @click="deleteItem(item)"
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Eiminar Tag</span>
                </v-tooltip>
              
              </template>


              <template v-slot:[`item.tagId`]="{ item }">
               {{parseInt(item.tagId)}}
              </template>

            </v-data-table>
          </v-card>
    
      </v-col>
    </v-row>
  </v-container>
</template>
  

<script>
import {storage} from '../firebase'
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage'
import {db} from '../firebase'
import {setDoc, doc, getDocs, collection, updateDoc, deleteDoc} from 'firebase/firestore'
import { mapActions, mapGetters, mapState } from 'vuex'
import router from '../router/index'
export default {
  name: 'Tags',
  data() {
    return {
      showLoading: false,
      search: '',
      radio: null,
      urlFile: '',
      localFile: null,
      dialog: false,
      dialogFile: false,
      clientEnabledDisabled: '',
      enabledAll: true,
      dialogEnabledDisabled: false,
      headers: [
        { text: 'Habilitado', value: 'enabled'},
        { text: 'ID Cliente', value: 'clientId'},
        { text: 'Cliente', value: 'clientDesc'},
        { text: 'ID Tag', value: 'tagId'},
        { text: 'Descripción Tag', value: 'desc'},
        { text: 'Descripción URL', value: 'urlDesc'},
        { text: 'URL', value: 'url'},
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        clientId: '',
        clientDesc: '',
        tagId: '',
        url: '',
        urlDesc: '',
        enabled: true,
      },
      defaultItem: {
        clientId: '',
        clientDesc: '',
        tagId: '',
        url: '',
        urlDesc: '',
        enabled: true
      }
      
    }
  },

  computed: {
    ...mapState(['user','tags']),
    ...mapGetters(['isAdmin'])
  },

  methods: {
    ...mapActions(['getTags','editTag']),

    async deleteItem (item) {
      await deleteDoc(doc(db, `tags/${item.clientId}/list`, item.tagId))
      this.loadTags()
    },

    loadTags(){
      if(this.user){
        const clientsArrayId = this.user.clients.map(item => item)
        this.$store.commit('CLEAR_TAGS')
        clientsArrayId.forEach(item => this.getTags(item.clientId))
      }
      else {router.push('/')}
    },

    editItem (item) {
      this.editedIndex = this.tags.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      },

    editFile (item) {
      this.editedIndex = this.tags.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogFile = true
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }) 
    },

    closeEnabledDisabled () {
      this.dialogEnabledDisabled = false
      this.clientEnabledDisabled = ''
      this.enabledAll = true
    },

    closeFile () {
      this.dialogFile = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.localFile = null
        this.urlFile = ''
        //console.log(this.localFile)
        //console.log(this.urlFile)
      })
    },

    save () {
      if (this.editedIndex > -1) {
        this.editTag(this.editedItem)
        this.loadTags()
      } else {
          
      } 
      this.close() 
    },


    selectFile(event){
      this.localFile = null
      this.urlFile=''
      //console.log(event.target.files[0])
      this.localFile = event.target.files[0]
      //console.log(event.target.files)

      if(this.localFile.type.match(/^.*image/)){
        //console.log("Es imagen")
        const reader = new FileReader()
        reader.readAsDataURL(this.localFile)
        reader.onload = e => {
          //console.log(e.target.result)
          this.urlFile = e.target.result
      }
      }
      else {
        //console.log("No es imagen")
        this.urlFile=''
      }
      //console.log(this.localFile)
      
    },

     async saveFile(){
      this.showLoading = true
      try {
          const refLogo = ref(storage, `${this.editedItem.clientId}/${this.localFile.name}`)
          const res = await uploadBytes(refLogo, this.localFile)
          const url = await getDownloadURL(ref(storage, `${this.editedItem.clientId}/${this.localFile.name}`))
          const clientRef = doc(db, `tags/${this.editedItem.clientId}/list`, this.editedItem.tagId)
          await setDoc(clientRef, {url: url}, {merge: true})
          this.loadTags()
        this.closeFile()
        this.showLoading = false
      } catch (error) {
        console.log(error)
      }
    },

    enabledDisabledAll(){
      this.dialogEnabledDisabled = true
    },

    async saveEnabledDisabledAll(){
      this.showLoading = true
      //console.log(this.clientEnabledDisabled)
      //console.log(this.enabledAll)
      const querySnapshot = await getDocs(collection(db, `tags/${this.clientEnabledDisabled}/list`))
      querySnapshot.forEach(doc =>{
          this.updateEnabledDisabledAll(this.clientEnabledDisabled, doc.id, this.enabledAll)
          //console.log(doc.id)
        })

      this.loadTags()
      this.closeEnabledDisabled()
      this.showLoading = false
    },

    async updateEnabledDisabledAll(client, id, enabled){
      try {
        const docRef = doc(db, `tags/${client}/list`, id)
        await updateDoc(docRef, { enabled: enabled }, { merge: true })
      } catch (error) {
        console.log(error)
      }
    }

  },

  created(){
    this.loadTags()
  },

  watch: {
    /*dialog (val) {
      val || this.close()
    },*/

    /*dialogFile (val) {
      val || this.closeFile()
    }*/
  },
}
</script>

<style>

</style>